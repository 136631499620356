@tailwind base;
@tailwind components;
@tailwind utilities;
/* index.css or globals.css */

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body {
  background-color: #F5F5F5;
  font-family: 'Inter', sans-serif;
}
